import { graphql } from "gatsby";
import { createGatsbyPageComponent } from "@shapeable/web";
import { ReflectionLayout } from "../../components/entities/reflection-layout";

export default createGatsbyPageComponent("Reflection", { layout: ReflectionLayout });

export const QUERY = graphql`
  query ReflectionQuery($id: ID!) {
    platform {
      reflection(id: $id) {
        id slug path __typename _schema { label pluralLabel }
        name name_fr name_de
        type { id name slug __typename }
        published
        openGraph { id image { thumbnails { full { url } } } }
        intro { id plain text }
        intro_fr { id plain text }
        intro_de { id plain text }
        summary { id plain text }
        summary_fr { id plain text }
        summary_de { id plain text }
        title title_fr title_de
        subtitle subtitle_fr subtitle_de
        pretitle pretitle_fr pretitle_de 
        content { id plain text }
        content_fr { id plain text }
        content_de { id plain text }
        outro { id plain text }
        outro_fr { id plain text }
        outro_de { id plain text }
        imageAssets { 
          id name image { id url static { childImageSharp { gatsbyImageData } } }
        }
        imageAssets_fr {
          id name image { id url static { childImageSharp { gatsbyImageData } } }
        }
        imageAssets_de {
          id name image { id url static { childImageSharp { gatsbyImageData } } }
        }
        topics { 
          id name slug __typename _schema { label pluralLabel }
          openGraph { id image { id url } }
        }
        challenges { 
          id name slug __typename _schema { label pluralLabel }
          openGraph { id image { id url } }
        }
        authors {
          id name slug path __typename _schema { label pluralLabel }
          organisation { id name }
          linkedin
          role { id name slug __typename _schema { label pluralLabel } }
          isMember
          bio { id text html }
          websiteUrl
          photo { id url static { childImageSharp { gatsbyImageData } } }
          positions {
            id
            primary
            positions {
              id 
              name 
              name_fr
              name_de
            }
            organisation { id name }
          }
        }
        embeds {
          citations {
            slug url name authors { id name slug } authorShowsEtAl edition publication date accessDate startPage volume footnoteNumber year
          }
          pages {
            id name slug path
            openGraph {
              title description { plain }
              image { url static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } }
            }
          }
          people {
            id name slug path
            organisation { id name }
            position
            photo { id url }
          }
          imageAssets {
            id
            slug
            image {
              id url width height static { childImageSharp { gatsbyImageData(placeholder: NONE) } } 
            }
          }
        }
        banners {
          id name slug title description { id text } alternateText
          image { 
            id url width height static { childImageSharp { gatsbyImageData(placeholder: NONE) } } 
          }
        }
      }
    }
  }
`

