import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { Reflection } from '@shapeable/villars-types';
import { breakpoints, theme } from '@shapeable/theme';
import { ComponentPropsProvider, ContentEntityBlock, SliceLayoutBoundary, useEntity } from '@shapeable/ui';
import { PostBannerLayout } from '@shapeable/web';
import { SliceLayoutColorStrip } from '../slice-layouts'; 
import { disclaimer } from '../../data';
import { classNames } from '@shapeable/utils';
const cls = classNames('reflection-layout');

// -------- Types -------->

export type ReflectionLayoutProps = Classable & HasChildren & { 
  entity?: Reflection;
};

export const ReflectionLayoutDefaultProps: Omit<ReflectionLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const FooterStyles = breakpoints({
  base: css`
    display: flex;
    align-items: center;
    margin-bottom: ${theme.UNIT(8)};

  `,
});

const DisclaimerStyles = breakpoints({
  base: css`
    text-align: center;
    font-style: italic;
    color: rgb(85, 87, 89);
  `,
});

const ContentStyles = breakpoints({
  base: css`
  
  `,
});



// -------- Components -------->

const My = {
  Container: styled(PostBannerLayout)<ContainerProps>`${ContainerStyles}`,
  Footer: styled.div`${FooterStyles}`,
      Disclaimer: styled.span`${DisclaimerStyles}`,
  Content: styled(ContentEntityBlock)`${ContentStyles}`,
};

export const ReflectionLayout: Shapeable.FC<ReflectionLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);

  return (
  <ComponentPropsProvider value={{ 
    PageMeta: {
      alignAuthors: true,
      photoSize: 60,
      useTooltip: true,
      showBadge: true
    }
  }}>
   <My.Container className={cls.name(className)} afterHeader={<SliceLayoutColorStrip />} entity={entity} />
   <SliceLayoutBoundary verticalPadding='medium' boundary='content-single'>
    <My.Disclaimer>{disclaimer}</My.Disclaimer>
   </SliceLayoutBoundary>
  </ComponentPropsProvider>
  )
};

ReflectionLayout.defaultProps = ReflectionLayoutDefaultProps;
ReflectionLayout.cls = cls;